import { createStore } from 'vuex'
import chat from './modules/chat'
import websocket from './modules/websocket'
import bingoPlugin from './plugins/bingoPlugin'
import bingo from './modules/bingo'
import game from './modules/game'

export default createStore({
    modules: {
        chat,
        websocket,
        bingo,
        game
    },
    plugins: [bingoPlugin()]
})

import types from './types'
import { global, subscribeUnicast } from '../../../utils/endpointBuilder'

export default {
    [types.SEND_MESSAGE]: async ({ rootState }, payload) => {
        rootState.websocket.client.send(global('sendMessage'), payload)
    },
    [types.ONUNMOUNT]: async ({ commit }, payload) => {
        commit(types.ONUNMOUNT)
    },
    [types.ONMOUNT]: async ({ commit, rootState }, payload) => {
        const object = []
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'receiveMessage'), function (val) {
            commit(types.SEND_MESSAGE, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'newUser'), function (val) {
            commit(types.NEW_USER, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'users'), function (val) {
            commit(types.ALL_USERS, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'removeUser'), function (val) {
            commit(types.REMOVE_USER, val.body)
        }))

        commit(types.ONMOUNT, object)
    }
}

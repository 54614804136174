import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            redirect: '/lobby'
        },
        {
            path: '/lobby',
            name: 'lobby',
            component: () => import('../views/Lobby.vue')
        },
        {
            path: '/creator',
            name: 'creator',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/Creator.vue')
        },
        {
            path: '/bingo',
            name: 'bingo',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/Bingo.vue')
        }
    ]
})

export default router

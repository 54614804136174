import types from './types'

export default {
    [types.ONMOUNT] (state, payload) {
        state.subscriptions = payload
    },
    [types.CHECK_GAMEPIN] (state, payload) {
        state.gameType = payload.toLowerCase()
    },
    [types.GET_GAME_NAMES] (state, payload) {
        const object = JSON.parse(payload)
        state.gameNames = object.games
    },
    [types.RESET_GAMETYPE] (state, payload) {
        state.gameType = undefined
    },
    [types.SET_GAMETYPE] (state, payload) {
        state.gameType = payload
    },
    [types.SET_TIMEOUT] (state, payload) {
        state.startTimeout = payload
    },
    [types.ONUNMOUNT] (state) {
        state.subscriptions.forEach(element => {
            element.unsubscribe()
        })
    }
}

export default {
    isConnected: state => {
        return state.client ? state.client.connected : false
    },
    getClient: state => {
        return state.client
    },
    getClientId: state => {
        return state.clientId
    }
}

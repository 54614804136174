export default {
    LEAVE_GAME: 'LEAVE_GAME',
    START_GAME: 'START_GAME',
    PAUZE_GAME: 'PAUZE_GAME',
    STOP_GAME: 'STOP_GAME',
    CHECK_GAMEPIN: 'CHECK_GAMEPIN',
    RESET_GAMETYPE: 'RESET_GAME',
    RESTART_GAME: 'RESTART_GAME',
    SET_GAMETYPE: 'SET_GAMETYPE',
    GET_GAME_NAMES: 'GET_GAME_NAMES',
    ONMOUNT: 'ONMOUNT',
    ONUNMOUNT: 'ONUNMOUNT',
    SET_TIMEOUT: 'SET_TIMEOUT'
}

import types from './types'

export default {
    [types.OPEN_CONNECTION] (state, payload) {
        state.client = payload
    },
    [types.DISCONNECTED] (state) {
        state.connected = false
    },
    [types.CONNECTED] (state) {
        state.connected = true
        clearTimeout(state.timeout)
        state.timeout = undefined
    },
    [types.SET_TIMEOUT] (state, payload) {
        state.timeout = payload
    }
}

import types from './types'
import bingoPlugin from '../../plugins/bingoPlugin'
import store from '../../'

export const RECONNECT_INTERVAL = 1000

export default {
    [types.OPEN_CONNECTION]: async ({ commit }, payload) => {
        commit(types.OPEN_CONNECTION, payload)
    },
    [types.CONNECTED]: async ({ commit }) => {
        commit(types.CONNECTED)
    },
    [types.DISCONNECTED]: async ({ dispatch, commit }) => {
        commit(types.DISCONNECTED)
        dispatch(types.RECONNECT)
    },
    [types.SET_TIMEOUT]: async ({ commit }, payload) => {
        commit(types.SET_TIMEOUT)
    },
    [types.RECONNECT]: async ({ commit }) => {
        const timeout = setTimeout(() => {
            const connectWebsocket = bingoPlugin()
            connectWebsocket(store)
        }, RECONNECT_INTERVAL)
        commit(types.SET_TIMEOUT, timeout)
    }
}

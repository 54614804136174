export default {
    gamePin: undefined,
    bingoCards: [],
    bingoBalls: [],
    figure: [],
    challenges: [],
    selectedNumbers: [],
    fakeChallenges: [],
    admin: false,
    winners: {},
    restart: false,
    player: true,
    subscriptions: []
}

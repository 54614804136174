import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import types from '../modules/websocket/types'
import gameTypes from '../modules/game/types'
import { uuid } from '../../utils/uuid'

export default () => {
    return (store) => {
        const SockJSOptions = {
            sessionId: () => {
                // (2) Check if a session ID already exhists
                let sessionId = localStorage.getItem('sockjsSessionId')
                if (sessionId === null || sessionId === undefined) {
                    // (3) If Session ID does not exist, create a random ID.
                    sessionId = uuid()
                    console.log('this connection has been created with the sessionId: ', sessionId)
                    localStorage.setItem('sockjsSessionId', sessionId)
                }
                // (4) Pass session id to SockJS Server
                return sessionId
            }
        }
        const socket = new SockJS('/api/game_server', null, SockJSOptions)
        const options = {
            debug: false,
            protocols: [Stomp.VERSIONS.V1_2]
        }
        const stompClient = Stomp.over(socket, options) // this can be improved see: https://stomp-js.github.io/stomp-websocket/codo/mixin/Stomp.html
        stompClient.reconnect_delay = 1000
        stompClient.connect(
            { clientId: store.getters['websocket/getClientId'] },
            function (frame) {
                store.dispatch('websocket/' + types.OPEN_CONNECTION, stompClient)
                store.dispatch('websocket/' + types.CONNECTED)
                store.dispatch('game/' + gameTypes.ONMOUNT)
            },
            function (message) {
                store.dispatch('websocket/' + types.DISCONNECTED)
            }
        )
    }
}

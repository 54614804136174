import types from './types'
import { gameSpecific, subscribeUnicast } from '../../../utils/endpointBuilder'

export default {
    [types.ENTER_GAME]: async ({ rootState }, payload) => {
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'enter'), JSON.stringify(payload))
    },
    [types.CREATE_GAME]: async ({ commit, rootState }, payload) => {
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'create'), JSON.stringify(payload))
    },
    [types.GET_BINGO_CARDS]: async ({ rootState }, payload) => {
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'getBingoCards'))
    },
    [types.GET_FIGURE]: async ({ rootState }, payload) => {
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'getFigure'))
    },
    [types.TOGGLE_NUMBER]: async ({ commit, rootState }, payload) => {
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'toggleNumber'), JSON.stringify(payload))
        commit(types.TOGGLE_NUMBER, payload)
    },
    [types.SET_PLAYER]: async ({ commit, rootState }, payload) => {
        commit(types.SET_PLAYER, payload)
    },
    [types.RESET_RESTART]: async ({ commit, rootState }, payload) => {
        commit(types.RESET_RESTART)
    },
    [types.ONUNMOUNT]: async ({ commit, rootState }, payload) => {
        commit(types.ONUNMOUNT, payload)
    },
    [types.ONMOUNT]: async ({ commit, rootState }, payload) => {
        const object = []

        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'create'), function (val) {
            commit(types.CREATE_GAME, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'getBingoCards'), function (val) {
            commit(types.GET_BINGO_CARDS, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'getFigure'), function (val) {
            commit(types.GET_FIGURE, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'setAdmin'), function (val) {
            commit(types.SET_ADMIN)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'newBingoBall'), function (val) {
            commit(types.NEW_BINGOBALL, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'fakeChallenge'), function (val) {
            commit(types.FAKE_CHALLENGE, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'winner'), function (val) {
            commit(types.WINNER, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'restart'), function (val) {
            commit(types.RESTART, true)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'getChallenges'), function (val) {
            commit(types.GET_CHALLENGES, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(subscribeUnicast(rootState.game.gameType, 'recoverData'), function (val) {
            console.log(val.body)
            commit(types.RECOVER_DATA, val.body)
        }))

        commit(types.ONMOUNT, object)
    },
    [types.RECOVER_DATA]: async ({ commit, rootState }) => {
        console.log('recover_data')
        rootState.websocket.client.send(gameSpecific(rootState.game.gameType, 'recoverData'))
    }
}

import types from './types'

export default {
    [types.ONMOUNT] (state, payload) {
        state.subscriptions = payload
    },
    [types.CREATE_GAME] (state, payload) {
        state.gamePin = payload
    },
    [types.SET_PLAYER] (state, payload) {
        state.player = payload
    },
    [types.GET_BINGO_CARDS] (state, payload) {
        const object = JSON.parse(payload)
        state.bingoCards = object.bingoCards
        for (let i = 0; i < object.bingoCards.length; i++) {
            state.selectedNumbers[i] = []
            state.fakeChallenges[i] = 0
        }
    },
    [types.GET_CHALLENGES] (state, payload) {
        const object = JSON.parse(payload)
        state.challenges = object.challenges.reverse()
    },
    [types.GET_FIGURE] (state, payload) {
        const object = JSON.parse(payload)
        state.figure = object.figure
    },
    [types.FAKE_CHALLENGE] (state, payload) {
        const object = JSON.parse(payload)
        for (const number of object.numbers) {
            state.selectedNumbers[object.cardNumber] = state.selectedNumbers[object.cardNumber].filter(function (value) { return value !== number })
        }

        if (object.challenge === 'bingo') {
            state.fakeChallenges[object.cardNumber] += 2
        } else {
            state.fakeChallenges[object.cardNumber] += 1
        }
    },
    [types.SET_ADMIN] (state, payload) {
        state.admin = true
    },
    [types.WINNER] (state, payload) {
        const object = JSON.parse(payload)
        for (const key in object) {
            state.winners[key] = object[key]
        }
        // if (object.vertical !== undefined) state.winVertical = object.vertical
        // if (object.horizontal !== undefined) state.winHorizontal = object.horizontal
        // if (object.figure !== undefined) state.winFigure = object.figure
        // if (object.card !== undefined) state.winCard = object.card
    },
    [types.NEW_BINGOBALL] (state, payload) {
        state.bingoBalls.push(payload)
    },
    [types.TOGGLE_NUMBER] (state, payload) {
        if (state.selectedNumbers[payload.cardNumber].includes(payload.position)) {
            state.selectedNumbers[payload.cardNumber] = state.selectedNumbers[payload.cardNumber].filter((value) => { return value !== payload.position })
        } else {
            state.selectedNumbers[payload.cardNumber].push(payload.position)
        }
        console.log(state.selectedNumbers)
    },
    [types.RESTART] (state, payload) {
        state.restart = payload
        state.bingoCards = []
        state.bingoBalls = []
        state.figure = []
        state.winners = {}
        state.selectedNumbers = []
        state.fakeChallenges = []
    },
    [types.RESET_RESTART] (state, payload) {
        state.restart = false
    },
    [types.ONUNMOUNT] (state) {
        state.gamePin = undefined
        state.bingoCards = []
        state.bingoBalls = []
        state.figure = []
        state.winners = {}
        state.selectedNumbers = []
        state.fakeChallenges = []
        state.challenges = []
        state.admin = false
        state.restart = false
        state.player = true
        state.subscriptions.forEach(element => {
            element.unsubscribe()
        })
        state.subscriptions = undefined
    },
    [types.RECOVER_DATA]: (state, payload) => {
        const object = JSON.parse(payload)
        state.gamePin = object.gamePin
        state.bingoCards = object.bingoCards
        state.bingoBalls = object.bingoBalls
        state.figure = object.figure
        state.selectedNumbers = object.selectedNumbers
        state.fakeChallenges = object.fakeChallenges
        state.challenges = object.challenges
        state.admin = object.admin
        state.restart = false
        state.player = true
    }
}

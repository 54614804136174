<template>
    <div>
        <div id="background"/>
        <transition
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
        mode="out-in"
        >
            <router-view :key="changed"></router-view>
        </transition>
    </div>
</template>
<script>
// needed to add this crapy code to create a transition between multiple pages
// :key in router-view is needed so every url change creates a new instance
// the code below is needed because router-view wont show the component when the :key is changed immediately
export default {
    data () {
        return {
            changed: false
        }
    },
    watch: {
        $route: function (newVal, oldVal) {
            if (oldVal.name !== undefined) {
                this.changed = !this.changed
            }
        }
    }
}
</script>
<style>
    body{
        margin: 0px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    :root {
        --animate-delay: 0.5s !important;
    }

    #background{
        position: fixed;
        background: url("assets/background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        z-index: -1;
    }

    body::-webkit-scrollbar {
        display: none;
    }
</style>

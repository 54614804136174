export default {
    getGamePin: state => {
        return state.gamePin
    },
    getBingoCards: state => {
        return state.bingoCards
    },
    getBingoBalls: state => {
        return state.bingoBalls
    },
    getFigure: state => {
        return state.figure
    },
    getPlayer: state => {
        return state.player
    },
    getChallenges: state => {
        return state.challenges
    },
    getselectedNumbers: state => {
        return state.selectedNumbers
    },
    getAmountOfBingoCards: state => {
        return state.bingoCards.length
    },
    getBingoCardRows: state => {
        if (state.bingoCards.length === 2) return 2
        return Math.ceil(state.bingoCards.length / 2)
    },
    getAdmin: state => {
        return state.admin
    },
    getFakeChallenges: state => {
        return state.fakeChallenges
    },
    getWinners: state => {
        return state.winners
    },
    getRestart: state => {
        return state.restart
    }
}

const subscribeUnicast = (type, name) => {
    // console.log(`/user/api/topic/${type}/${name}`)
    return `/user/api/topic/${type}/${name}`
}

const subscribeBroadcast = (type, name) => {
    // console.log(`/api/topic/${type}/${name}`)
    return `/api/topic/${type}/${name}`
}

const gameSpecific = (type, name) => {
    // console.log(`/api/game/${type}/${name}`)
    return `/api/game/${type}/${name}`
}

const global = (name) => {
    // console.log(`/api/game/${name}`)
    return `/api/game/${name}`
}

const globalSubscribeBroadcast = (name) => {
    // console.log(`/api/topic/${name}`)
    return `/api/topic/${name}`
}

const globalSubscribeUnicast = (name) => {
    // console.log(`/user/api/topic/${name}`)
    return `/user/api/topic/${name}`
}

export {
    subscribeBroadcast,
    subscribeUnicast,
    gameSpecific,
    global,
    globalSubscribeBroadcast,
    globalSubscribeUnicast
}

import generalTypes from '../../types'

export default {
    ...generalTypes,
    GET_BINGO_CARDS: 'GET_BINGO_CARDS',
    GET_FIGURE: 'GET_FIGURE',
    TOGGLE_NUMBER: 'TOGGLE_NUMBER',
    NEW_BINGOBALL: 'NEW_BINGOBALL',
    FAKE_CHALLENGE: 'FAKE_CHALLENGE',
    WINNER: 'WINNER',
    RESET_RESTART: 'RESET_RESTART',
    GET_CHALLENGES: 'GET_CHALLENGES',
    SET_PLAYER: 'SET_PLAYER',
    RECOVER_DATA: 'RECOVER_DATA'
}

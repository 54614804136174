export default {
    getGameType: state => {
        return state.gameType
    },
    getGameNames: state => {
        return state.gameNames
    },
    getStartTimeout: state => {
        return state.startTimeout
    },
    getTimeout: state => {
        return state.timeout
    }
}

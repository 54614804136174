import types from './types'

export default {
    [types.ONMOUNT] (state, payload) {
        state.subscriptions = payload
    },
    [types.SEND_MESSAGE] (state, payload) {
        var jsonObj = JSON.parse(payload)
        state.chatMessages.push([jsonObj.user, jsonObj.message])
    },
    [types.NEW_USER] (state, payload) {
        var jsonObj = JSON.parse(payload)
        state.users.push([jsonObj.clientId, jsonObj.userName])
    },
    [types.REMOVE_USER] (state, payload) {
        var jsonObj = JSON.parse(payload)
        for (var i = 0; i < state.users.length; i++) {
            if (state.users[i][0] === (jsonObj.clientId)) {
                state.users.splice(i, 1)
            }
        }
    },
    [types.ALL_USERS] (state, payload) {
        var jsonObj = JSON.parse(payload)
        for (var i = 0; i < jsonObj.users.length; i++) {
            var clientId = jsonObj.users[i].clientId
            var userName = jsonObj.users[i].userName
            if (!state.users.includes([clientId, userName])) {
                state.users.push([clientId, userName])
            }
        }
    },
    [types.ONUNMOUNT] (state, payload) {
        state.chatMessages = []
        state.users = []
        state.subscriptions.forEach(element => {
            element.unsubscribe()
        })
        state.subscriptions = undefined
    }
}

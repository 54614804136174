import types from './types'
import router from '../../../router'
import { global, globalSubscribeUnicast } from '../../../utils/endpointBuilder'

export default {
    [types.LEAVE_GAME]: async ({ commit, rootState }) => {
        rootState.websocket.client.send(global('leave'))
    },
    [types.START_GAME]: async ({ commit, rootState }) => {
        rootState.websocket.client.send(global('start'))
    },
    [types.PAUZE_GAME]: async ({ commit, rootState }) => {
        rootState.websocket.client.send(global('pauze'))
    },
    [types.STOP_GAME]: async ({ commit, rootState }) => {
        rootState.websocket.client.send(global('stop'))
    },
    [types.GET_GAME_NAMES]: async ({ commit, rootState }) => {
        rootState.websocket.client.send(global('getGameNames'))
    },
    [types.RESTART_GAME]: async ({ commit, rootState }, payload) => {
        rootState.websocket.client.send(global('restart'))
    },
    [types.CHECK_GAMEPIN]: async ({ rootState }, payload) => {
        rootState.websocket.client.send(global('checkGamePin'), JSON.stringify(payload))
    },
    [types.RESET_GAMETYPE]: async ({ commit }) => {
        commit(types.RESET_GAMETYPE)
    },
    [types.SET_GAMETYPE]: async ({ commit }, payload) => {
        commit(types.SET_GAMETYPE, payload)
    },
    [types.ONUNMOUNT]: async ({ commit, rootState }, payload) => {
        commit(types.ONUNMOUNT, payload)
    },
    [types.ONMOUNT]: async ({ commit, rootState }, payload) => {
        const object = []
        object.push(rootState.websocket.client.subscribe(globalSubscribeUnicast('checkGamePin'), function (val) {
            if (val.body === 'locked' && rootState.game.startTimeout === undefined) {
                commit(types.SET_TIMEOUT, new Date())
            } else if (val.body !== 'locked' && rootState.game.startTimeout !== undefined) {
                commit(types.SET_TIMEOUT, undefined)
            }
            commit(types.CHECK_GAMEPIN, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(globalSubscribeUnicast('gameNames'), function (val) {
            commit(types.GET_GAME_NAMES, val.body)
        }))
        object.push(rootState.websocket.client.subscribe(globalSubscribeUnicast('stop'), function (val) {
            router.push('/lobby')
        }))
        commit(types.ONMOUNT, object)
    }
}
